import SolagroAPIClient from '../api/SolagroAPIClient';

const client = new SolagroAPIClient('/api');

if (/\b(authToken)=(([a-z0-9_-]+\.){2}[a-z0-9_-]+)\b/i.test(document.cookie)) {
    client.authorizationToken = RegExp.$2;
    client.authorizationTokenVerified = false;
    document.cookie = `${RegExp.$1}=; expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
    localStorage.setItem('authorizationToken', client.authorizationToken);
} else {
    client.authorizationToken = localStorage.getItem('authorizationToken');
}

if (!client.authorizationToken || client.authorizationToken === 'null') {
    client.authorizationToken = null;
}

export default client;
