import React, { ReactElement } from 'react';
import { useValidateAuth } from '../hooks/api';
import { LoadingScreen } from '../screens/LoadingScreen';
import { Footer } from './Footer';
import { Header } from './Header';

export interface ScreenWrapperProps {
    children: ReactElement | ReactElement[];
}

export const ScreenWrapper = ({ children }: ScreenWrapperProps) => {
    const isLoading = useValidateAuth();

    if (isLoading) {
        return <LoadingScreen />;
    }

    return (
        <div className="c-wrapper o-grid o-grid--column o-grid--flush o-grid--auto">
            <Header />
            <div className="c-wrapper__content o-grid o-grid--auto o-grid--flush o-grid__cell">
                {children}
            </div>
            <Footer />
        </div>
    );
};
