import React, {useContext} from "react";
import {rs} from "../i18n/rs";
import {en} from "../i18n/en";
import {AppContext} from "../AppContext";
import headerBeli from "../../assets/images/header-beli.jpg";
import reciklomat from "../../assets/images/reciklomat.jpg";
import phone from "../../assets/images/phone.jpg";
import can from "../../assets/images/can.jpg"
import popust25 from "../../assets/images/popust25.jpg"
import one from "../../assets/images/1.png"
import two from "../../assets/images/2.png"
import three from "../../assets/images/3.png"
import four from "../../assets/images/4.png"
import solagroIcon from "../../assets/images/slgr_logo.png"
import bg from "../../assets/images/bcg.png"
import footerNew from "../../assets/images/footer.png"
import svakalimenka from "../../assets/images/svakalimenka.png"
import {navigate} from "hookrouter";
import {Button} from "../components/Button";
import {useValidateAuth} from "../hooks/api";
import {LoadingScreen} from "./LoadingScreen";

const Home = () => {
    const isLoading = useValidateAuth({onSuccess: '/gifts', initialLoading: true});
    const context = useContext(AppContext);

    if (isLoading) {
        return <LoadingScreen/>;
    }

    return <div className="u-container-column" style={{background: 'white'}}>
        <img src={bg} className="c-home-center__background"/>
        <div className="c-home-wrapper">
            <div className="c-home-header">
                <div className="c-home-language">
                    <a onClick={() => context.setContent(rs)}>[SRP]</a>
                    <a style={{marginLeft: '8px'}} onClick={() => context.setContent(en)}>[ENG]</a>
                </div>
                <img src={headerBeli} style={{width: '70%'}}/>
            </div>
            <div className="c-home-center">
                <span style={{ color: '#76AE45', fontSize: '8px', margin: '0 auto', marginTop: '20px'}}>powered by</span>
                <img src={solagroIcon} style={{height: '50px', width: '70px', alignSelf: 'center'}}/>
                <div className="c-home-center__main">

                    <div className="c-home-center__main__icons">
                        <img style={{maxHeight: '500px'}}
                             src={reciklomat}/>
                        <img style={{maxHeight: '500px', marginTop: '20px'}} src={phone}/>
                    </div>
                    <div className="c-home-center__main__titles">
                        <div className="c-home-center__main__titles__element">
                            <img src={one} className="c-home-center__main__titles__element__image"/>
                            <div
                                className="c-home-center__main__titles__element__text_wrap__text">{context.content.home.collect_cans}</div>
                            <img style={{maxHeight: '70px', marginLeft: '20px'}} src={can}/>
                        </div>
                        <div className="c-home-center__main__titles__element">
                            <img src={two} className="c-home-center__main__titles__element__image"/>
                            <div className="c-home-center__main__titles__element__text_wrap">
                                <div
                                    className="c-home-center__main__titles__element__text_wrap__text">{context.content.home.recycle_cans}</div>
                                <div
                                    className="c-home-center__main__titles__element__text_wrap__subtext">{context.content.home.recycle_cans_sub}</div>
                            </div>

                        </div>
                        <div className="c-home-center__main__titles__element">
                            <img src={three} className="c-home-center__main__titles__element__image"/>
                            <div
                                className="c-home-center__main__titles__element__text_wrap__text">{context.content.home.machine_code}</div>
                        </div>
                        <div className="c-home-center__main__titles__element">
                            <img src={four} className="c-home-center__main__titles__element__image"/>
                            <div style={{display: 'flex', flexDirection: 'column'}}>
                                <div
                                    className="c-home-center__main__titles__element__text_wrap__text">{context.content.home.enjoy}</div>
                                <img src={popust25} style={{maxWidth: '50%'}}/>
                            </div>
                        </div>
                    </div>
                </div>
                <Button
                    className="c-btn c-btn--text u-vertical-margin-small"
                    style={{
                        marginTop: '20px',
                        width: '50%',
                        marginLeft: '0px',
                        minWidth: 240,
                        fontWeight: '600',
                        borderRadius: '10',
                        color: 'white',
                        backgroundColor: 'green',
                        fontSize: '12px'
                    }}
                    type="button"
                    onClick={() => navigate('/signup')}
                    color="white"
                >
                    {context.content.home.register}
                </Button>
                <Button
                    className="c-btn c-btn--text u-vertical-margin-small"
                    style={{
                        width: '50%',
                        minWidth: 240,
                        marginLeft: '0px',
                        height: '40px',
                        fontWeight: '600',
                        borderRadius: '10',
                        color: 'white',
                        backgroundColor: '#76AE45',
                        fontSize: '12px'
                    }}
                    type="button"
                    onClick={() => navigate('/signin')}
                    color="white"
                >
                    {context.content.home.signin}
                </Button>
            </div>
            <img src={svakalimenka} style={{width: '30%'}}/>
            <img style={{maxWidth: '70%'}} src={footerNew}/>
        </div>
    </div>
}

export default Home;
