import React from 'react';
import loaderImage from '../../assets/images/loader.gif';

export function LoadingScreen() {
    return (
        <div className="c-loader">
            <img src={loaderImage as string} width="200" height="150" />
        </div>
    );
}
