import { navigate } from 'hookrouter';
import { useContext, useEffect, useState } from 'react';
import {env} from '../../server/env';
import client from '../api/ApiInstance';
import { ResourceParams } from '../api/SolagroAPIClient';
import { AppContext, User } from '../AppContext';

export function useValidateAuth({
    onError: onError = '/',
    onSuccess,
    initialLoading,
    navigateToRootOnAuthError = true,
}: {
    onError?: string | null;
    onSuccess?: string;
    initialLoading?: boolean;
    navigateToRootOnAuthError?: boolean;
} = {}) {
    const [get, isLoading, setIsLoading] = useRequest(
        'GET',
        initialLoading === undefined ? !client.authorizationTokenVerified : initialLoading,
        navigateToRootOnAuthError,
    );
    const [isVerificationDone, setIsVerificationDone] = useState(false);

    const context = useContext(AppContext);

    useEffect(() => {
        if (client.authorizationTokenVerified) {
            setIsVerificationDone(true);
            setIsLoading(false);
            if (typeof onSuccess === 'string') {
                navigate(onSuccess);
            }
        } else if (client.authorizationToken === null) {
            setIsVerificationDone(true);
            setIsLoading(false);
            if (typeof onError === 'string') {
                navigate(onError);
            }
        } else {
            setIsVerificationDone(false);
            get('/users/me', { params: { ts: Date.now(), partner_id: env.BELGRADE_PARTNER_PUBLIC_ID } })
                .then(async (res: User) => {
                    context.setUser(res);

                    const locations = await client.get('/locations', {
                        params: { ts: Date.now(), partner_id: env.BELGRADE_PARTNER_PUBLIC_ID },
                    });
                    const gifts = await client.get('/products', {
                        params: { limit: 100, ts: Date.now(), partner_id: env.BELGRADE_PARTNER_PUBLIC_ID },
                    });

                    const purchased = await client.get('/users/me/products', {
                        params: { limit: 100, ts: Date.now(), partner_id: env.BELGRADE_PARTNER_PUBLIC_ID },
                    });

                    context.setGifts(gifts);
                    context.setLocations(locations);
                    context.setPurchased(purchased);

                    client.authorizationTokenVerified = true;

                    if (typeof onSuccess === 'string') {
                        navigate(onSuccess);
                    }
                })
                .catch(() => {
                    client.invalidateAuthState();
                    if (typeof onError === 'string') {
                        navigate(onError);
                    }
                })
                .then(
                    () => {
                        setIsVerificationDone(true);
                    },
                    () => {
                        setIsVerificationDone(true);
                    },
                );
        }
    }, []);

    return isLoading || !isVerificationDone;
}

export function useRequest(
    method: 'GET' | 'POST' | 'PUT' | 'DELETE',
    initialLoading = false,
    navigateToRootOnAuthError = true,
): [typeof sendRequest, boolean, typeof setIsLoading] {
    const [isLoading, setIsLoading] = useState(initialLoading);

    async function sendRequest(resource: string, params: ResourceParams = {}) {
        try {
            setIsLoading(true);
            return await client.fetch({ method, resource, ...params });
        } catch (error) {
            if (error && typeof error === 'object' && (error as any).status === 401) {
                client.invalidateAuthState();
                if (navigateToRootOnAuthError) {
                    navigate('/');
                }
            } else {
                throw error;
            }
        } finally {
            setIsLoading(false);
        }
    }

    return [sendRequest, isLoading, setIsLoading];
}
