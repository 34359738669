import React from 'react';
import { A, LinkProps } from 'hookrouter';

export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    href?: string;
}

export const Button = ({ href, ...props }: ButtonProps) => {
    const className = `c-btn${typeof props.className == 'string' ? ' ' + props.className : ''}`;
    if (typeof href === 'string') {
        const linkProps = { ...props, className, href } as LinkProps;
        const prefix = href.substring(0, 4);
        if (prefix === 'http' || href.substring(0, 6) === '/auth/') {
            return <a {...linkProps} />;
        } else return <A {...linkProps} />;
    }

    return <button {...{ type: 'button', ...props, className }} />;
};
