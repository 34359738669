export interface ResourceParams {
    params?: { [key: string]: string | number | boolean | null };
    headers?: { [key: string]: string };
}

export interface FetchParams extends ResourceParams {
    method: 'GET' | 'POST' | 'PUT' | 'DELETE';
    resource: string;
}

export default class SolagroAPIClient {
    serverUrl: string;
    authorizationToken: string | null;
    authorizationTokenVerified = false;

    constructor(serverUrl: string) {
        this.serverUrl = serverUrl.replace(/\/+$/, '') + '/';
        this.authorizationToken = null;
    }

    get authenticated() {
        return !!this.authorizationToken;
    }

    async fetch({ method = 'GET', resource, params = {}, headers = {} }: FetchParams) {
        if (!resource) {
            throw new TypeError('Required parameter missing: resource');
        }

        const init: Partial<FetchParams> & { body?: string } = {
            method,
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json; charset=utf-8',
                ...headers,
            },
        };

        if (
            this.authenticated &&
            init.headers != undefined &&
            !init.headers.Authorization &&
            !init.headers.authorization
        ) {
            init.headers.Authorization = `Bearer ${this.authorizationToken}`;
        }

        let query = '';

        if (method.toUpperCase() !== 'GET') {
            init.body = JSON.stringify(params);
        } else {
            const queryParams = [];
            for (const key of Object.keys(params)) {
                const name = encodeURIComponent(key);
                const paramValue = params[key];
                if (Array.isArray(paramValue)) {
                    queryParams.push(
                        ...paramValue.map((value) => `${name}=${encodeURIComponent(value)}`),
                    );
                } else if (params[key] !== undefined) {
                    queryParams.push(`${name}=${encodeURIComponent(params[key])}`);
                }
            }
            if (queryParams.length > 0) {
                query = '?' + queryParams.join('&');
            }
        }

        let response;

        try {
            response = await fetch(
                `${this.serverUrl}${resource.replace(/^\/+/, '')}${query}`,
                init,
            );
        } catch (error) {
            throw { status: -1, error };
        }

        let body;
        try {
            body = await response.json();
        } catch (e) {
            if (!response.ok) {
                throw { status: response.status };
            } else {
                throw { status: -1, error: e };
            }
        }

        if (!response.ok) {
            throw {
                status: response.status,
                details: body,
            };
        }

        if (body.status !== 'OK') {
            throw {
                status: -1,
                details: body,
            };
        }

        return body.data;
    }

    async post(resource: string, { params, headers }: ResourceParams = {}) {
        return this.fetch({ method: 'POST', resource, params, headers });
    }

    async put(resource: string, { params, headers }: ResourceParams = {}) {
        return this.fetch({ method: 'PUT', resource, params, headers });
    }

    async get(resource: string, { params, headers }: ResourceParams = {}) {
        return this.fetch({ method: 'GET', resource, params, headers });
    }

    async delete(resource: string, { params, headers }: ResourceParams = {}) {
        return this.fetch({ method: 'DELETE', resource, params, headers });
    }

    async signup(email: string, password: string, confirmPassword: string, fullName: string, gender: string, age: number) {
        const { token } = await this.post('users', {
            params: { email, password, confirmPassword, fullName, gender, age},
        });
        this.authorizationToken = token;
        this.authorizationTokenVerified = true;
        localStorage.setItem('authorizationToken', token);
        return true;
    }

    async acceptPrivacyPolicy(id: string) {
        await this.post('/privacy_policy_accepted', {
            params: { ts: Date.now(), user_id: id }
        });

        return true;
    }

    async login(email: string, password: string) {
        const { token } = await this.post('tokens', {
            params: { email, password },
        });
        this.authorizationToken = token;
        this.authorizationTokenVerified = true;
        localStorage.setItem('authorizationToken', token);
        return true;
    }

    async logout() {
        try {
            // tslint:disable-next-line: no-floating-promises
            this.delete('tokens/bearer'); // intentionally not awaiting the request to complete
            return true;
        } catch (e) {
            return false;
        } finally {
            this.invalidateAuthState();
        }
    }

    invalidateAuthState() {
        this.authorizationToken = null;
        this.authorizationTokenVerified = false;
        localStorage.setItem('authorizationToken', null);
    }
}
