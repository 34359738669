import { attempt, ObjectSchema, TYPE } from '@solagro/validate';
import Joi, { ValidationError } from 'joi';
export * from '@solagro/validate';

const isSchema = TYPE.object()
    .schema()
    .required();

export interface AppEnv {
    [key: string]: string | boolean | number | undefined;
}

export function getEnv<T extends AppEnv>(envSchema: ObjectSchema<T>): Readonly<T> {
    attempt(envSchema, isSchema, 'Environment schema is required');

    const result = Joi.validate(process.env, envSchema, {
        abortEarly: false,
        stripUnknown: true,
        presence: 'required',
    });

    if ((result.error as ValidationError | null) !== null) {
        throw result.error;
    }

    return Object.freeze(result.value) as Readonly<T>;
}
