import React from 'react';
import { Button } from './Button';
import shoppingCartImage from '../../assets/images/gift_bottom.png';
import navigationImage from '../../assets/images/location_bottom.png';
import qrCodeImage from '../../assets/images/qr_bottom.png';

export const Footer = () => {
    return (
        <div className="c-footer">
            <div className="c-footer__content u-container">
                <div className="u-container">
                    <Button href="/purchased">
                        <img height="50px" width="50px" src={shoppingCartImage} />
                    </Button>
                </div>
                <div className="u-container o-grid__cell--align-start u-pull-vertical-40-percent">
                    <Button href="/redeem">
                        <img height="100px" width="100px" src={qrCodeImage} />
                    </Button>
                </div>
                <div className="u-container">
                    <Button href="/locations">
                        <img height="50px" width="50px" src={navigationImage} />
                    </Button>
                </div>
            </div>
        </div>
    );
};
