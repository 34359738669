import React, { Suspense } from 'react';
import { LoadingScreen } from './LoadingScreen';

export interface DynamicScreenProps {
    params?: {};
    screen(): Promise<{ default: React.ComponentType<any> }>;
}

export const DynamicScreen = ({ screen, params }: DynamicScreenProps) => {
    const Screen = React.lazy(screen);
    return (
        <Suspense fallback={<LoadingScreen />}>
            <Screen {...params} />
        </Suspense>
    );
};
