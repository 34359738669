export const en = {
    home: {
        signin: 'LOG IN',
        register: 'REGISTER THROUGH E-MAIL',
        collect_cans: 'COLLECT CANS',
        recycle_cans: 'PUT AWAY CANS IN SMART PRESS\nSmart presses are located in Idea stores find locations',
        recycle_cans_sub: 'Smart presses are located\nin Idea stores find locations',
        machine_code: 'PRESS WILL SHOW CODE WHICH YOU SHOULD ENTER IN APPLICATION',
        enjoy: 'ENJOY YOUR PRIZES',
        enter_code: 'ENTER CODE\nFROM SMART PRESS',
        collect_points: 'USE COLLECTED POINTS\nAND PICK YOUR PRIZES',
        press_locations: 'PRESS LOCATIONS IN\nIDEA STORES',
        signin_with_facebook: 'Log in with Facebook'
    },
    locations: {
        nearest: 'Closest location:',
        location_title: 'IDEA Organic',
        location_cta: 'START NAVIGATION',
    },
    points: {
        total_points: 'Total points',
        add_points: 'ADD POINTS',
    },
    profile: {
        beginner: 'Beginner',
        professional: 'Professional',
        maestro: 'Maestro',
        grand_maestro: 'Grand Maestro',
        genius: 'Genius',
        supergenius: 'SUPERGENIUS',
        username: 'Username',
        welcome: 'WELCOME',
        statistics: 'Statistics',
        recycled_bottles: 'RECYCLED BOTTLES',
        energy_saved: 'ENERGY SAVED IN KW',
        co2_saved: `LESS CO2 IN Kg`,
    },
    purchased: {
        no_reservations: "You haven't reserved any gifts yet",
    },
    redeem: {
        input_code: 'Input the code from the press and confirm',
        confirm: 'Confirm',
        err_nocode: 'You have to input the code.',
        err_invalid: 'Code is invalid!',
    },
    signin: {
        err_no_email: 'Please enter your e-mail address',
        err_invalid_email: 'Invalid e-mail address',
        err_no_pass: 'Please enter your password',
        err_invalid_pass: 'Invalid e-mail or password',
        login: 'Log in',
        register: 'Not registered yet? Register here.',
        forgot_password: 'Forgot your password?',
        email: 'E-mail address',
        password: 'Password',
        social_login: 'Other log in options',
    },
    forgot_password: {
        err_no_email: 'Please enter your e-mail address',
        err_invalid_email: 'Invalid e-mail address',
        err_unknown: 'Unknown error. Please try again.',
        msg_success:
            'If there is an account with the provided e-mail address you will get further instructions via e-mail message.',
        email: 'E-mail address',
        reset_password: 'Reset password',
        register: 'Not registered yet? Register here.',
        login: 'Log in',
        instructions: 'Please enter an e-mail address you have used to register and we will send you the message with password reset instructions.',
        back: 'Go back',
    },
    reset_password: {
        err_no_pass: 'Please enter your password',
        err_short_pass: 'Password has to be longer than 5 characters',
        err_no_confirm: 'Please confirm your password',
        err_no_match: 'Passwords do not match',
        err_unknown: 'Unknown error. Please try again.',
        msg_success: 'You have successfully changed your password. Now you can sign in using the new password.',
        msg_invalid_token: 'This link is no longer valid. Please request a new password reset link.',
        change_password: 'Change password',
        login: 'Log in',
        instructions: 'Please enter new password',
        back: 'Nazad',
        pass: 'Password',
        confirm_pass: 'Confirm Password',
    },
    account_verification: {
        please_verify: 'Please verify your account',
        account_verified: 'Account verified!',
        account_verified_expl: 'Now you can access your account.',
        continue: 'Continue',
        check_email: 'Please check your email for account verification.',
        back: 'Nazad',
    },
    privacy_policy: {
        privacy_policy_title: `Privacy policy`,
        terms_and_conditions: `Terms and conditions`,
        privacy_policy_updated: `We're making updates to our Privacy Policy and wanted to share the details with you.`,
        agree: `I agree`,
        review: 'Review Privacy policy',
    },
    signup: {
        err_no_username: 'Please enter your username',
        err_short_username: 'Username needs to be longer than 4 characters',
        err_invalid_username: 'Username contains invalid characters',
        err_no_pass: 'Please enter your password',
        err_short_pass: 'Password has to be longer than 5 characters',
        err_no_confirm: 'Please confirm your password',
        err_no_match: 'Passwords do not match',
        err_no_email: 'Please enter your e-mail',
        err_invalid_email: 'Invalid e-mail address',
        err_try_again: 'Error, try again',
        err_policy: 'Error, privacy policy not accepted',
        username: 'Username',
        email: 'E-mail',
        pass: 'Password',
        confirm_pass: 'Confirm Password',
        choose_gender: "Choose your gender",
        gender_array: ['male', 'female', 'unknown', 'other'],
        age: 'Enter your age',
        signup: 'Register',
        has_account: 'Already have an account? Log in.',
    },
    operator_stats: {
        choose_partner: 'Choose a partner',
        choose_duration: 'Choose a duration',
        details: 'Details',
    },
    gift_details: {
        sponsor: 'Reward provided by:',
        success: 'Your purchase was successful!',
        failed: 'Purchase failed. Please try again',
        not_enough_points: 'Not enough points!',
        available: 'Available quantity:',
        reserve: 'RESERVE REWARD',
        unavailable: 'The reward you are looking for is unavailable at the moment!',
        are_you_sure: 'Are you sure you want to spend %s points on this reward?',
        yes: 'Yes',
        no: 'No',
    },
};
